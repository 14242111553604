import http from "./http";

export default {
    getalldata(filterAll) {
        return http
            .get("users" + filterAll)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    getDataById(id) {
        return http
            .get("users/" + id)
            .then(function (response) {
                return Promise.resolve(response);
        })
        .catch(function (error) {
            return Promise.reject(error);
        });
    },
    getdataByMe() {
        return http
            .get("users-me")
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            })
    },
    getDataByCusID(id) {
        return http
            .get("users-customer/" + id)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    getCustomerOptionsdata() {
        return http
            .get("customers-all")
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            })
    },
    insertData(form) {
        return http
            .post("users", form)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    insertWithCus(id, form) {
        return http
            .post("users-cusId/" + id, form)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    updateData(id, form) {
        return http
            .put("users/" + id, form)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    updateByCustomer(id, form) {
        return http
            .put("users-customer/" + id, form)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    deleteData(id) {
        return http
            .delete("users/" + id)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    deletedata(id) {
        return http
            .delete("users/" + id)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },


   
}