<template>
  <div>
    <UsersAddNew
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      :role-options="roleOptions"
      :customerOptions="customerOptions"
      @refetch-data="refetchData"
    />
    <UsersEdit
      :is-edit-sidebar-active.sync="isEditSidebarActive"
      :role-options="roleOptions"
      :customerOptions="customerOptions"
      :formData="formData"
      @refetch-data="refetchData"
    />
    <b-card>
      <b-row>
        <b-col md="3" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t("Sort") }}</label>
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="6" class="my-1 d-none d-md-block"></b-col>

        <b-col md="6" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t("Filter") }}</label>
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                :placeholder="$t('Search')"
                @keyup="searchEnter()"
                @keydown="searchEnter()"
                @change="searchEnter()"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="searchClear()">
                  {{ $t("Clear") }}
                </b-button>

                <b-button
                  style="margin-left: 5px; border-radius: 0.357rem"
                  variant="primary"
                  @click="isAddNewSidebarActive = true"
                >
                  <span class="text-nowrap">{{ $t("Add") }} {{ $t("User") }}</span>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table
            striped
            responsive
            :per-page="perPage"
            :current-page="currentPagetable"
            :items="bigdata"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            @sort-changed="sortingChanged"
          >
            <template #cell(show_details)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                  </span>
                </span>
                <span class="vs-label">{{
                  row.detailsShowing ? "Hide" : "Show"
                }}</span>
              </b-form-checkbox>
            </template>

            <template #cell(username)="row">
              <div v-if="row.item.username == ''"></div>
              <div v-else>{{ row.item.username }}</div>
            </template>

            <template #cell(password)="row">
              <div v-if="row.item.password == ''"></div>
              <div v-else>******</div>
            </template>

            <template #cell(role)="row">
              <div v-if="row.item.role == 'superadmin'">Super Admin</div>
              <div v-else-if="row.item.role == 'admin'">Admin</div>
              <div v-else-if="row.item.role == 'customer'">Customer</div>
              <div v-else>User</div>
            </template>

            <template #cell(cusName)="row">
              <div v-if="row.item.cusName == ''"></div>
              <div v-else>
                <b-link
                  :to="{
                    name: 'customer-detail',
                    params: { id: row.item.cusCode },
                  }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ row.item.cusName }}
                </b-link>
              </div>
            </template>

            <template #cell(block)="row">
              <div v-if="row.item.block == '0' && row.item.last_login">
                <b-badge variant="success">Active</b-badge>
              </div>
              <div v-else-if="row.item.block == '1'"><b-badge variant="danger">Blocked</b-badge></div>
              <div v-else><b-badge variant="secondary">Inactive</b-badge></div>
            </template>

            <template #cell(actions)="row">
              <b-dropdown
                v-if="row.item.role != 'superadmin'"
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="editOpen(row.item.id)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">{{ $t("Edit") }}</span>
                </b-dropdown-item>

                <b-dropdown-item @click="deleteData(row.item.id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">{{ $t("Delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>

          <!--vue-good table -->
          <!-- <vue-good-table
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            :group-options="{
              enabled: true
            }"
            @on-sort-change="onSortChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <span
                v-if="props.column.field === 'username'"
                class="text-nowrap"
              >
                <span class="text-nowrap">{{ props.row.username }}</span>
              </span>

              <span v-else-if="props.column.field === 'password'">
                <span v-if="props.row.password == ''"></span>
                <span v-else>******</span>
              </span>

              <span v-else-if="props.column.field === 'role'">
                <span v-if="props.row.role == '0'">Admin</span>
                <span v-else-if="props.row.role == '1'">Customer</span>
                <span v-else-if="props.row.role == '2'">User</span>
                <span v-else>ไม่ระบุ</span>
              </span>

              <span v-else-if="props.column.field === 'cusName'">
                <span v-if="props.row.cusName == ''"></span>
                <span v-else>
                  <b-link
                    :to="{ name: 'customer-detail', params: { id: props.row.cusCode } }"
                    class="font-weight-bold d-block text-nowrap"
                  >
                  {{ props.row.cusName }}
                  </b-link>
                </span>
              </span>

             
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item>
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>{{ $t("Edit") }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item>
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span>{{ $t("Delete") }}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>

            
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table> -->
        </b-col>

        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t("Per page") }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              @change="perPageChanged()"
              class="w-50"
            />

            <div class="show-entries" v-if="totalRows == 0">
              <label class="text-muted">{{ $t("Showing") }} 0 {{ $t("To") }}  0 {{ $t("Of") }}  0 {{ $t("Entries") }} </label>
            </div>
            <div class="show-entries" v-else>
              <div v-if="currentPage * perPage > totalRows">
                <label class="text-muted"
                  >{{ $t("Showing") }} {{ currentPage * perPage + 1 - perPage }} {{ $t("To") }}
                  {{ currentPage * perPage }} {{ $t("Of") }} {{ totalRows }} {{ $t("Entries") }}</label
                >
              </div>
              <div v-else>
                <label class="text-muted"
                  >{{ $t("Showing") }} {{ currentPage * perPage + 1 - perPage }} {{ $t("To") }}
                  {{ currentPage * perPage }} {{ $t("Of") }} {{ totalRows }} {{ $t("Entries") }}</label
                >
              </div>
            </div>
          </b-form-group>
        </b-col>

        <b-col md="10" sm="8" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @click.native="handlePageChange()"
            align="right"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BCardHeader,
  BCardText,
  BLink,
  BTable,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BImg,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import jwt_decode from "jwt-decode";
import usersService from "@/services/usersService.js";
import customerService from "@/services/customerService.js";
import UsersAddNew from "../users/UsersAddNew.vue";
import UsersEdit from "../users/UsersEdit.vue";
import { ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    VueGoodTable,
    BCard,
    BCardHeader,
    BCardText,
    BLink,
    BTable,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BImg,
    BDropdown,
    BDropdownItem,
    UsersAddNew,
    UsersEdit,
  },
  directives: {
    Ripple,
  },
  setup() {
    const isAddNewSidebarActive = ref(false);
    const isEditSidebarActive = ref(false);

    const roleOptions = [
      { label: "Super Admin", value: "superadmin" },
      { label: "Admin", value: "admin" },
      { label: "Customer", value: "customer" },
    ];

    return {
      // Sidebar
      isAddNewSidebarActive,
      isEditSidebarActive,

      roleOptions,
    };
  },
  data() {
    return {
      perPage: 20,
      pageOptions: [2, 20, 30, 50, 100],
      totalRows: 1,
      currentPage: 1,
      currentPagetable: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      role: "",
      fields: [
        // {
        //   key: "show_details",
        //   label: "Details",
        //   class: "d-sm-none",
        // },
        {
          key: "username",
          label: this.$t("Username"),
          sortable: true,
          class: "text-left",
        },
        // {
        //   key: "password",
        //   label: "Password",
        //   class: "text-center",
        // },
        {
          key: "role",
          label: this.$t("User Role"),
          sortable: true,
          class: "text-left",
        },
        {
          key: "cusName",
          label: "Customer Ref.",
          class: "text-left",
        },
        {
          key: "last_login",
          label: "Last Login",
          sortable: true,
        },
        {
          key: "block",
          label: "Status",
          sortable: true,
          class: "text-left",
        },
        {
          key: "actions",
          label: this.$t('Actions'),
          class: "text-center",
        },
      ],
      filterAll: "",
      filterSearch: "",
      filterDate: "",
      filterperPage: "",
      filtersortBy: "",
      filtersortDesc: "",
      bigdata: [],
      customerOptions: [],
      formData: [],

      dir: false,
      columns: [
        {
          label: this.$t("Username"),
          field: "username",
        },
        {
          label: this.$t("Password"),
          field: "password",
          sortable: false,
        },
        {
          label: this.$t("User Role"),
          field: "role",
        },
        {
          label: "Customer Ref.",
          field: "cusName",
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  mounted() {},
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updateDate() {
      var start = dateFormat(this.dateRange.startDate, "yyyy-mm-dd");
      var end = dateFormat(this.dateRange.endDate, "yyyy-mm-dd");
      if (this.dateRange.startDate && this.dateRange.endDate) {
        this.filterDate = "&startDate=" + start + "&endDate=" + end;
        this.getbigdata();
      }
    },
    searchEnter() {
      if (this.filter) {
        this.filterSearch = "&search=" + this.filter;
      } else {
        this.filterSearch = "";
      }
      this.getbigdata();
    },
    searchClear() {
      this.filter = "";
      this.filterSearch = "";
      this.getbigdata();
    },
    perPageChanged() {
      this.filterperPage = "&per_page=" + this.perPage;
      this.getbigdata();
    },
    sortingChanged(e) {
      if (e.sortBy) {
        this.filtersortBy = "&sortBy=" + e.sortBy;
      }

      if (e.sortDesc) {
        this.filtersortDesc = "&sortDesc=DESC";
      } else {
        this.filtersortDesc = "&sortDesc=ASC";
      }
      this.getbigdata();
    },
    handlePageChange() {
      this.getbigdata();
    },
    getbigdata() {
      var page = "page=" + this.currentPage;
      this.filterAll =
        "?" +
        page +
        this.filterSearch +
        this.filterperPage +
        this.filtersortBy +
        this.filtersortDesc;

      try {
        usersService
          .getalldata(this.filterAll)
          .then((response) => {
            this.bigdata = response.data.data.data;
            this.totalRows = response.data.data.total;
            this.rows = response.data.data_group;
          })
          .catch(() => {});

        // this.data1 = response.data;
        // console.log(this.User_data);
      } catch (e) {
        // console.log(e);
      }
    },
    editOpen(id) {
      try {
        usersService
          .getDataById(id)
          .then((response) => {
            this.formData = response.data.data;
            if (this.formData.cusId == 0) {
              this.formData.cusId = "";
            }
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
      this.isEditSidebarActive = true;
    },
    deleteData(key_id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          try {
            usersService
              .deleteData(key_id)
              .then((response) => {
                console.log(response);
                this.getbigdata();
              })
              .catch(() => {});
          } catch (e) {
            // console.log(e);
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "ลบข้อมูลสำเร็จ",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }
      });
    },
    getCustomerOptions() {
      try {
        customerService
          .getCustomerOptionsdata(this.filterAll)
          .then((response) => {
            this.customerOptions = response.data.data;
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
    showtoken() {
      var token = localStorage.getItem("token");
      var decoded = jwt_decode(token);
      // console.log(decoded);
      // console.log(decoded.role);
      // if (decoded.role == "superadmin") {
      //   this.role = true;
      // } else {
      //   this.role = false;
      // }
      this.role = decoded.role;

      if (this.role == "admin") {
        this.roleOptions = [];
        this.roleOptions.push(
          { label: "Admin", value: "admin" },
          { label: "Customer", value: "customer" }
        );
      }
    },
    refetchData() {
      this.getbigdata();
    },
  },
  async created() {
    await this.getbigdata();
    await this.getCustomerOptions();
    await this.showtoken();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
table.vgt-table {
  font-size: 14px;
}
</style>
