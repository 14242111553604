<template>
  <b-sidebar
    id="add-edit-sidebar"
    :visible="isEditSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-edit-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Edit User</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(updatedata)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="Username"
            rules="required"
          >
            <b-form-group :label="$t('Username')" label-for="username">
              <b-form-input
                id="username"
                v-model="formData.username"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                readonly
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Password"
            rules="min:3"
          >
            <b-form-group :label="$t('Password')" label-for="password">
              <b-form-input
                id="password"
                v-model="password"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required|min:3"
          >
            <b-form-group label="Full Name" label-for="fullname">
              <b-form-input
                id="fullname"
                v-model="formData.fullname"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Role"
            rules="required"
          >
            <b-form-group
              :label="$t('User Role')"
              label-for="user-role"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="formData.role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="(val) => val.value"
                input-id="user-role"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Customer Ref."
            rules="required"
            v-if="formData.role == 'customer'"
          >
            <b-form-group
              label="Customer Ref."
              label-for="cusId"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="formData.cusId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="customerOptions"
                :reduce="(option) => option.cusId"
                label="cusName"
                input-id="cusId"
                @input="cusRefChange"
              />

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="block"
            rules="required"
          >
            <b-form-group
              label="User Blocked"
              label-for="user-block"
              :state="getValidationState(validationContext)"
            >
              <b-form-checkbox
                :checked="formData.block == 1 ? true : false"
                class="custom-control-danger"
                name="check-button"
                switch
                @change="toggleBlock($event)"
              >
                <!-- <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" style="color: white" />
                </span> -->
              </b-form-checkbox>
              <!-- <b-form-input
                id="UserBlock"
                v-model="formData.block"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Token"
              />

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback> -->
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("Update") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
            {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import usersService from "@/services/usersService.js";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditSidebarActive",
    event: "update:is-edit-sidebar-active",
  },
  props: {
    isEditSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    customerOptions: {
      type: Array,
      required: true,
    },
    formData: {},
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      password: "",
      customerOpt: {},
    };
  },
  setup() {
    const { refFormObserver, getValidationState } = formValidation();

    return {
      refFormObserver,
      getValidationState,
    };
  },
  computed: {},
  methods: {
    toggleBlock($event) {
      if ($event) {
        this.formData.block = 1;
      } else {
        this.formData.block = 0;
      }
    },

    cusRefChange(value) {
      this.customerOpt = this.customerOptions.filter((x) => x.cusId === value);
      this.customerOpt = this.customerOpt[0];
      this.formData.cusId = this.customerOpt.cusId;
      this.formData.cusCode = this.customerOpt.cusCode;
    },
    updatedata() {
      this.formData.password = this.password;
      //this.formData.block = this.block;
      //this.formData.block = this.formData.block;
      try {
        usersService
          .updateData(this.formData.id, this.formData)

          .then((response) => {
            console.log(response);
            if (response.data.status == "error") {
              this.$swal({
                type: "error",
                title: "อัพเดทข้อมูลไม่สำเร็จ",
                text: "Error! " + response.data.message,
                timer: 3000,
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "อัพเดทข้อมูลสำเร็จ",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$emit("refetch-data");
              this.$emit("update:is-edit-sidebar-active", false);
            }
          })
          .catch(() => {});
      } catch (e) {
        //console.log(e);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-edit-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
